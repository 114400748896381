import React from "react";
import { graphql } from "gatsby";
import Layout from "./../components/Layout";
import ProgrammeHero from "./../components/ProgrammeHero";
import ProgrammeBody from "./../components/ProgrammeBody";
import EventsSectionFilter from "../components/EventsSectionFilter";
import { timeFilters, getStartDate, cleanCraftDate } from "./../modules/time";
import EventGrid, { EmptyEventGrid } from "./../components/EventGrid";
import { eventTypeList } from "./../modules/eventTypes";
import { H3, H3Strong } from "./../components/Typography";
import styled from "styled-components";

const allTimes = () => true;

const H3Spotlight =styled.h3`
font-weight: 600;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 0.32px;
  margin: 0;
  position:relative;
  @media (max-width: 767px) {
    margin: 0px 25px 25px;
    text-align: center;
  }
`;

const ProgrammeTopText=styled.div`
maxWidth: var(--width);
padding: var(--outerMargin);
margin: "0 auto";
@media (max-width: 767px) {
  margin-top:100px;
}
`;

const DateCardDiv = styled.div`
  display: flex;
  border-top: 1.5px solid var(--accentColor);
  padding-top: 20px;
  width: ${(props) =>
    props.size === 1 || props.size === 2
      ? "var(--cardWidthWide)"
      : "var(--cardWidthNormal)"};
  color: var(--accentColor);
  border-bottom: var(--bottomRule);
  padding-bottom: var(--bottomPadding);
  @media (max-width: 767px) {
    width: 375px;
    & h2 {
      width: 100%;
      text-align: center;
      margin: 0;
    }
  }
`;
const ProgrammePage = ({ data, pageContext }) => {
  const {
    title,
    programmeHeadline,
    topline,
    programmeBackgroundGraphic,
    showOrHideTitleAndSubtitle,
    bannerGraphicPortrait,
    hideSubtitle,
    featuredEventsTitle,
    featuredEvents,
    programmeEventSelector,
    programmeHighlightedEventSelector,
    programmeSuperHighlightedEventSelector,
    programmeLogoGraphic,
    firstSectionText,
    secondSectionText,
    programmeDescriptionRichText
  } = data.craftEntryInterface;
  //console.log(data, pageContext.id);

  const [shownEvents, setShownEvents] = React.useState([]); // Do we need this?
  const [startDate, setStartDate] = React.useState(getStartDate("today"));
  const [shownFeaturedEvents, setShownFeaturedEvents] = React.useState([]);

  React.useEffect(() => {
    const eventList = [];
    const featuredList =[];
    // console.log("generating event list");
    //TODO: Filter in this.
    const now = new Date();

    
    if(featuredEvents!=null && featuredEvents.length>0)
    {
      for (let i = 0; i < featuredEvents.length; i++) {
        if (featuredEvents[i]) {
          const newEvent = featuredEvents[i];
          newEvent.focusLevel = 0;
          const thisDate = new Date(
            newEvent.eventEndDateTime || newEvent.eventStartDateTime
          );
          //if (thisDate >= now) {
            featuredList[featuredList.length] = newEvent;
          //}
        }
      }
    }

    
    if(programmeEventSelector!=null && programmeEventSelector.length>0)
    {
      for (let i = 0; i < programmeEventSelector.length; i++) {
        if (programmeEventSelector[i]) {
          const newEvent = programmeEventSelector[i];
          newEvent.focusLevel = 0;
          const thisDate = new Date(
            newEvent.eventEndDateTime || newEvent.eventStartDateTime
          );
          //if (thisDate >= now) {
            eventList[eventList.length] = newEvent;
          //}
        }
      }
    }


    for (let i = 0; i < programmeHighlightedEventSelector.length; i++) {
      if (programmeHighlightedEventSelector[i]) {
        const newEvent = programmeHighlightedEventSelector[i];
        newEvent.focusLevel = 1;
        const thisDate = new Date(
          newEvent.eventEndDateTime || newEvent.eventStartDateTime
        );
        //if (thisDate >= now) {
          eventList[eventList.length] = newEvent;
        //}
      }
    }
    for (let i = 0; i < programmeSuperHighlightedEventSelector.length; i++) {
      if (programmeSuperHighlightedEventSelector[i]) {
        const newEvent = programmeSuperHighlightedEventSelector[i];
        newEvent.focusLevel = 2;
        const thisDate = new Date(
          newEvent.eventEndDateTime || newEvent.eventStartDateTime
        );
        //if (thisDate >= now) {
          eventList[eventList.length] = newEvent;
        //}
      }
    }

    // this is to check for duplicates added by user
    // prommeSuperHighlighted should supersede Highlighted which should supersede Event

    const deDupedEventList = [];

    for (let i = 0; i < eventList.length; i++) {
      let dupeFound = false;
      let featured=false;
      const thisEvent = eventList[i].id;

      for (let j = 0; j < featuredList.length; j++) {
        if (featuredList[j].id === thisEvent && i !== j) {
          featured=true;
        }
      }

      for (let j = 0; j < eventList.length; j++) {
        if (eventList[j].id === thisEvent && i !== j) {
          if (eventList[i].focusLevel < eventList[j].focusLevel) {
            dupeFound = true;
          }
        }
      }
      
      if (!featured && !dupeFound) {
        deDupedEventList.push(eventList[i]);
      }
    }


    
    setShownEvents(deDupedEventList);
    setShownFeaturedEvents(featuredList);
    // console.log(featuredList);
  }, [
    featuredEvents,
    programmeEventSelector,
    programmeHighlightedEventSelector,
    programmeSuperHighlightedEventSelector,
  ]);

  const [currentTypes, setCurrentTypes] = React.useState(
    eventTypeList.map((x) => x.id)
  );
  // console.log(currentTypes);
  const [currentTimeFilter, setCurrentTimeFilter] = React.useState(
    () => allTimes
  );
  const [currentTimeFilterName, setCurrentTimeFilterName] =
    React.useState(null);

  const checkTypes = (typesArray) => {
    //console.log(typesArray)
    if(typesArray!=null && typesArray.length>0)
    {
        for (let i = 0; i < typesArray.length; i++) {
        if (currentTypes.indexOf(typesArray[i]) > -1) {
          return true;
        }
      }
    }
    
    // console.log(typesArray);
    return false;
  };

  // console.log(
  //   shownEvents
  //     .filter((x) =>
  //       currentTypes.length === 0 ? true : checkTypes(x.eventType)
  //     )
  //     .filter((x) =>
  //       currentTimeFilter(x.eventStartDateTime, x.eventEndDateTime)
  //     )
  // );
  return (
    <Layout
      backgroundColor={"var(--white)"}
      footerColor={"var(--red)"}
      skipHeader
      title={title}
    >
      <ProgrammeHero 
        isTop
        topline={topline}
        titleText={title}
        subTitleText={programmeHeadline}
        backgroundColor={"var(--green)"}
        color={"var(--white)"}
        titleColor={"var(--red)"}
        backgroundImage={
          programmeBackgroundGraphic.length
            ? programmeBackgroundGraphic[0]
            : null
        }
        hideDesktopTitle={showOrHideTitleAndSubtitle}
        mobilebanner={
          bannerGraphicPortrait.length ? bannerGraphicPortrait[0] :null
        }
        hideMobileTitle={hideSubtitle}
        titleImage={
          programmeLogoGraphic.length ? programmeLogoGraphic[0] : null
        }
      />
      {firstSectionText || secondSectionText ? (
        <div
          style={{ backgroundColor: "var(--white)" }}
          className="programmesubtitle"
        >
          <ProgrammeTopText>
          <H3Strong>
              <span style={{ color: "var(--red)" }}>{firstSectionText}</span> 
            </H3Strong>
            <p />
            
              <H3>{secondSectionText}</H3> 
              <H3 dangerouslySetInnerHTML={{__html: programmeDescriptionRichText}} />
          </ProgrammeTopText>
          
        </div>
      ) : null}
      <ProgrammeBody eventsPage>
        <EventsSectionFilter
          selected={(e) => {
            setCurrentTypes(e);
            if (!e.length) {
              // console.log("nothing selected!");
              setCurrentTimeFilter(() => allTimes);
            }
          }}
          timeSelected={(e, customDate) => {
            if (e === "thisDate") {
              // console.log("custom date", customDate);
              const customDateFilter = (start, end) => {
                const startDate = new Date(start);
                const endDate = new Date(end || start);
                const startTomorrow = new Date(
                  customDate.getFullYear(),
                  customDate.getMonth(),
                  customDate.getDate() + 1
                );
                return startDate <= startTomorrow && endDate >= customDate;
              };
              setCurrentTimeFilter(() => customDateFilter);
              setCurrentTimeFilterName("customDateFilter");
              setStartDate(() => customDate);
            } else {
              setCurrentTimeFilter(() => timeFilters[e]);
              setCurrentTimeFilterName(e);
              setStartDate(() => getStartDate(e));
            }
          }}
        />
        


        {shownFeaturedEvents
          .filter((x) =>
            currentTypes.length === 0 ? true : checkTypes(x.eventType)
          )
          .filter((x) =>
            currentTimeFilter(
              cleanCraftDate(x.eventStartDateTime),
              cleanCraftDate(x.eventEndDateTime)
            )
          ).length ? (
          
           <>
           <EventGrid
                events={shownFeaturedEvents
                  .filter((x) =>
                    currentTypes.length === 0 ? true : checkTypes(x.eventType)
                  )
                  .filter((x) =>
                    currentTimeFilter(
                      cleanCraftDate(x.eventStartDateTime),
                      cleanCraftDate(x.eventEndDateTime)
                    )
                  )}
                firstDate={startDate}
                firstDateType={currentTimeFilterName}
                showDate={false}
                isProgramme={false}
                featuredEventsTitle={featuredEventsTitle.length>0?featuredEventsTitle:'Featured'}
              />
           </>
        ) : null}


        {shownEvents
          .filter((x) =>
            currentTypes.length === 0 ? true : checkTypes(x.eventType)
          )
          .filter((x) =>
            currentTimeFilter(
              cleanCraftDate(x.eventStartDateTime),
              cleanCraftDate(x.eventEndDateTime)
            )
          ).length ? (
          <EventGrid
            events={shownEvents
              .filter((x) =>
                currentTypes.length === 0 ? true : checkTypes(x.eventType)
              )
              .filter((x) =>
                currentTimeFilter(
                  cleanCraftDate(x.eventStartDateTime),
                  cleanCraftDate(x.eventEndDateTime)
                )
              )}
            firstDate={startDate}
            firstDateType={currentTimeFilterName}
          />
        ) : (
          <EmptyEventGrid />
        )}
        {/*shownEvents
          .filter((x) =>
            currentTypes.length === 0 ? true : checkTypes(x.eventType)
          )
          .filter((x) =>
            currentTimeFilter(x.eventStartDateTime, x.eventEndDateTime)
          ).length ? (
          <ul>
            {shownEvents
              .filter((x) =>
                currentTypes.length === 0 ? true : checkTypes(x.eventType)
              )
              .filter((x) =>
                currentTimeFilter(x.eventStartDateTime, x.eventEndDateTime)
              )
              .map((card, index) => (
                <li key={index}>
                  <Link to={`/event/${card.slug}/`}>{card.title}</Link> (
                  {card.eventType}), {dateFormat(card.eventStartDateTime)}–
                  {dateFormat(card.eventEndDateTime)}
                </li>
              ))}
          </ul>
        ) : (
          <p>No events!</p>
				)*/}
      </ProgrammeBody>
    </Layout>
  );
};

export default ProgrammePage;

export const pageQuery = graphql`
  query ($id: String!) {
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_programmes_programmes_Entry {
        title
        topline
        firstSectionText: subtitle
        secondSectionText: caption
        programmeHeadline
        programmeBackgroundGraphic {
          ... on Craft_eventsAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(fit: COVER, width: 1440, height: 610) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        showOrHideTitleAndSubtitle
        bannerGraphicPortrait{ 
          ... on Craft_editorialAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 550) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          ... on Craft_eventsAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 550) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        hideSubtitle
        programmeDescriptionRichText
        programmeHeadline
        programmeLogoGraphic {
          ... on Craft_eventsAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(fit: CONTAIN, width: 1440, height: 610) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        featuredEventsTitle
        featuredEvents{
          ... on Craft_event_event_Entry {
            id
            title
            eventEndDateTime
            eventStartDateTime
            eventShortDescription
            slug
            eventType
            eventLocation {
              title
              slug
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        programmeEventSelector {
          ... on Craft_event_event_Entry {
            id
            title
            eventEndDateTime
            eventStartDateTime
            eventShortDescription
            slug
            eventType
            eventLocation {
              title
              slug
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        programmeHighlightedEventSelector {
          ... on Craft_event_event_Entry {
            id
            title
            eventEndDateTime
            eventStartDateTime
            eventShortDescription
            slug
            eventType
            eventLocation {
              title
              slug
            }

            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 300, width: 400, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        programmeSuperHighlightedEventSelector {
          ... on Craft_event_event_Entry {
            id
            title
            eventEndDateTime
            eventStartDateTime
            eventShortDescription
            slug
            eventType
            eventLocation {
              title
              slug
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 450, width: 610, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
